import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { normalizedData } from "@utils/functions";
import Layout from "@layout";
import SEO from "@components/seo";
import Footer from "../../layouts/footer/layout-01";
import Header from "../../layouts/header/layout-dark";
import PageBreadcrumb from "@components/pagebreadcrumb";
import Button from "../../components/shared/button";
import AgentAbout from "../../components/agent/agent-about";
import AgentExperiences from "../../components/agent/agent-experiences";
import AgentAreaProject from "../../components/agent/agent-area-project";
import AgentPropertyType from "../../components/agent/agent-property-type";

const AgentDetails = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.agentTeam.content || []);

    return (
        <Layout>
            <SEO title="Agent Details" pathname="/" />

            <Header data={{ ...globalContent?.["menu"] }} />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Agent Details"
            />
            <div className="agent-content-wrap section-space-pb border-b">
                <div className="container">
                    <div className="grid grid-cols-1 lg:grid-cols-12">
                        <div className="col-span-4">
                            <div className="profile-box max-w-[395px] bg-[#F3F3F9] p-10">
                                {data?.agentTeam?.image && (
                                    <div className="agent-image mb-6">
                                        <GatsbyImage
                                            image={getImage(
                                                data?.agentTeam?.image?.src
                                            )}
                                            alt={
                                                data?.agentTeam?.image?.alt ||
                                                "Agent Image"
                                            }
                                        />
                                    </div>
                                )}
                                {data?.agentTeam?.name && (
                                    <h3 className="agent-name mb-0">
                                        {data?.agentTeam?.name}
                                    </h3>
                                )}
                                {data?.agentTeam?.designation && (
                                    <span className="text-[13px] mb-6 block">
                                        {data?.agentTeam?.designation}
                                    </span>
                                )}
                                <ul className="agent-info mb-7">
                                    {data?.agentTeam?.phone && (
                                        <li className="text-black">
                                            <strong>Phone: </strong>
                                            <a
                                                className="text-[#515151] hover:text-primary"
                                                href={`tel:${data?.agentTeam?.phone}`}
                                            >
                                                {data?.agentTeam?.phone}
                                            </a>
                                        </li>
                                    )}
                                    {data?.agentTeam?.email && (
                                        <li className="text-black">
                                            <strong>Email: </strong>
                                            <a
                                                className="text-[#515151] hover:text-primary"
                                                href={`mailto:${data?.agentTeam?.email}`}
                                            >
                                                {data?.agentTeam?.email}
                                            </a>
                                        </li>
                                    )}
                                    {data?.agentTeam?.address && (
                                        <li className="text-black">
                                            <strong>Address: </strong>
                                            {data?.agentTeam?.address}
                                        </li>
                                    )}
                                    {data?.agentTeam?.license && (
                                        <li className="text-black">
                                            <strong>License: </strong>
                                            {data?.agentTeam?.license}
                                        </li>
                                    )}
                                    {data?.agentTeam?.area && (
                                        <li className="text-black">
                                            <strong>Area: </strong>
                                            {data?.agentTeam?.area}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-span-8">
                            <div className="agent-content">
                                <AgentAbout data={content["agent-about"]} />
                                <AgentExperiences
                                    data={content["agent-experiences"]}
                                />
                                <AgentAreaProject
                                    data={content["agent-area-project"]}
                                />
                                <AgentPropertyType
                                    data={content["agent-property-type"]}
                                />
                                <div className="agent-contact-form ajax-form mt-10">
                                    <h3 className="mb-6">Contact</h3>
                                    <p className="mb-4">
                                        Required fileds are marked *
                                    </p>
                                    <form action="#">
                                        <div className="grid grid-cols-2 gap-x-10 gap-y-5">
                                            <div className="single-input">
                                                <input
                                                    className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Your Name"
                                                />
                                            </div>
                                            <div className="single-input">
                                                <input
                                                    className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Your Phone"
                                                />
                                            </div>
                                            <div className="single-input col-span-2">
                                                <input
                                                    className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Your Email Address *"
                                                />
                                            </div>
                                            <div className="single-input col-span-2">
                                                <textarea
                                                    className="form-control h-44 w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                                                    name="message"
                                                    placeholder="How can we help?"
                                                ></textarea>
                                            </div>
                                            <div className="mt-4">
                                                <Button
                                                    color="light"
                                                    outline="dark"
                                                    type="submit"
                                                    className="px-16"
                                                >
                                                    submit Now
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                    <p className="form-messege"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

AgentDetails.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        agentTeam: PropTypes.shape({
            name: PropTypes.string,
            image: PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            }),
            designation: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            address: PropTypes.string,
            license: PropTypes.string,
            area: PropTypes.string,
            content: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]),
                })
            ),
        }),
    }),
};

export const postQuery = graphql`
    query AgentDetailsBySlug($slug: String!) {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        agentTeam(slug: { eq: $slug }) {
            id
            name
            slug
            phone
            email
            designation
            address
            license
            area
            image {
                alt
                src {
                    childImageSharp {
                        gatsbyImageData(quality: 100)
                    }
                }
            }
            content {
                section
                headings {
                    content
                }
                items {
                    desc
                    id
                    features {
                        id
                        content
                    }
                }
            }
        }
    }
`;
export default AgentDetails;
