import React from "react";
import PropTypes from "prop-types";

const AgentPropertyType = ({ data }) => {
    return (
        <div className="agent-area-project mt-10">
            {data?.headings && <h3>{data?.headings[0].content}</h3>}
            {data?.items.map((item) => (
                <div key={item.id}>
                    <p className="mt-4">{item.desc} </p>
                    {item?.features && (
                        <ul className="feature flex">
                            {item?.features?.map((feature) => (
                                <li
                                    key={feature.id}
                                    className="mb-1 inline-block relative after:content-[','] after:mr-1 last:after:sr-only"
                                >
                                    <a
                                        href="/"
                                        className="inline-block  hover:text-primary"
                                        dangerouslySetInnerHTML={{
                                            __html: feature.content,
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};

AgentPropertyType.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({ content: PropTypes.string })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default AgentPropertyType;
