import React from "react";
import PropTypes from "prop-types";

const AgentAbout = ({ data }) => {
    return (
        <div className="agent-about">
            {data?.headings && <h2>{data?.headings[0].content}</h2>}
            {data?.items &&
                data?.items.map((item) => (
                    <p key={item.id} className="mt-4">
                        {item.desc}
                    </p>
                ))}
        </div>
    );
};

AgentAbout.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({ content: PropTypes.string })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default AgentAbout;
