import React from "react";
import PropTypes from "prop-types";

const AgentExperiences = ({ data }) => {
    return (
        <div className="agent-experiences mt-10">
            {data?.headings && <h3>{data?.headings[0].content}</h3>}
            {data?.items.map((item) => (
                <div key={item.id}>
                    {item.desc && <p className="mt-4">{item.desc}</p>}
                    {item?.features && (
                        <ul className="feature">
                            {item?.features?.map((feature) => (
                                <li
                                    key={feature.id}
                                    className="pl-[27px] block mb-1 relative before:absolute before:content-[''] before:w-1 before:h-1 before:bg-black before:left-0 before:top-[11px] before:rounded-full"
                                    dangerouslySetInnerHTML={{
                                        __html: feature.content,
                                    }}
                                />
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};

AgentExperiences.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({ content: PropTypes.string })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.oneOfType([
                            PropTypes.string,
                            PropTypes.number,
                        ]),
                    })
                ),
            })
        ),
    }),
};

export default AgentExperiences;
